import React from 'react';
import ReactDOM from 'react-dom';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import App from './App';
import * as serviceWorker from './serviceWorker';
import Blog from './routes/blog'
import ErrorBoundary from './components/error-boundary/ErrorBoundary';

import './index.css';

const router = createBrowserRouter([
  {
    path: "/",
    element: <><ErrorBoundary><App /></ErrorBoundary></>
  },
  {
    path: "blogs/:blogId",
    element: <><ErrorBoundary><Blog /></ErrorBoundary></>
  },
]);


ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
    <RouterProvider router={router} />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import './index.css'

export default function ErrorPage({error, errorInfo}) {
  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error?.statusText || error?.message}</i>
        <i>{JSON.stringify(error)}</i>
        <i>{JSON.stringify(errorInfo)}</i>
      </p>
    </div>
  );
}

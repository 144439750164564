import React from "react";
import { Homepage } from "./containers/homepage";

import "./App.css";


function App(props) {
  return (       
    <Homepage />
  );
}

export default App;

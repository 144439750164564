import React from 'react';
import ErrorPage from '../../error-page';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: undefined, errorInfo: undefined };
  }

  componentDidCatch(error, errorInfo) { // You can also log the error to an error reporting service
    this.setState({ hasError: true, error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (<ErrorPage error={this.state.error} errorInfo={this.state.errorInfo} />);
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
